import { Drawer } from "antd";
import { Component } from "react";
// import { Link } from "react-router-dom";
// import { AiOutlineHome } from "react-icons/ai";
// import { MdOutlineLeaderboard } from "react-icons/md";
// import { AiOutlineShop } from "react-icons/ai";
// import { RiDiscordLine } from "react-icons/ri";
// import { FiHelpCircle } from "react-icons/fi";
// import { CgLogIn } from "react-icons/cg";
// import { withRouter } from "react-router-dom";
// import axios from "axios";

interface ClothingCardsProps {
    text: string;
    image: string;
    price: number;
    installments: number;
    sizes: string[];
}

class ClothingCard extends Component<ClothingCardsProps> {
    // constructor(props: SectionCardsProps) {
    //     super(props);

    // }

    render() {
        const { text, image, price, sizes, installments } = this.props;

        return (
            <>
                <div className="clothing-card">
                    <div>
                        <div className="inner">
                            <figure className="image" >
                                <img src={image} alt="" />
                                <div className="sizes">
                                    {sizes.map((size, i) =>
                                        (size)).join(', ')}
                                </div>
                            </figure>
                            <p className="title" title={text}>
                                {text ?? "none"}
                            </p>
                            <div>
                                <p className="price">R$ {price ?? "00,0"}</p>
                                <p className="installments"> {installments}x de R$ {(price / installments).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

export default ClothingCard;