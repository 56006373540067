import React, { Component, useState } from "react";
// import { connect } from "react-redux";
// import { Redirect, Route, Switch } from "react-router-dom";
// import { ROUTES } from "../../config/routes";
// import '../../styles/app.scss';
// import '../../styles/less/antd.less';
// import Banner from "../../components/Banner";
// import Footer from "../../components/Footer";
import { Button } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
// import Sidebar from "../../components/Sidebar";
// import { BrowserRouter, withRouter } from "react-router-dom";
// import { ROUTE_PATH } from "../../config/general";

interface IProps {
}

interface IState {
    data: TData;
}

type TData = {
    text: string;
    image: string;
    price: number;
    installments: number;
    sizes: string[];
}

class Item extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            data: {
                text: "acamisa viscose com estampa",
                image: "/assets/news-banner.jpg",
                price: 199.90,
                installments: 2,
                sizes: ["p", "m", "g", "xg", "xxg"],
            }
        };
    }

    componentDidMount() {
        document.body.classList.add("page-item");
    }

    render() {
        const { data } = this.state;

        return (
            <div id="site-main" className="container">
                <div className="logo">
                    <img alt="sdkasd" width='120px' height='120px' src='/assets/logobranco.png' />
                </div>
                <div className="content">
                    <div className="column">
                        <figure className="image" >
                            <img src={data.image} alt="" />
                        </figure>
                    </div>
                    <div className="column">
                        <p className="title" title={data.text}>
                            {data.text ?? "none"}
                        </p>
                        <div>
                            <p className="price">R$ {data.price ?? "00,0"}</p>
                            <p className="installments"> {data.installments}x de R$ {(data.price / data.installments).toFixed(2)} sem juros</p>
                        </div>
                        <div className="size-container">
                            <p className="title">Tamanhos</p>
                            <div className="sizes">
                                {data.sizes.map((size, i) => (
                                    <div className="size">
                                        <p>
                                            {size}
                                        </p>
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                        <div className="buttons-container">
                            <Button type="primary" size="large">Finalizar compra (Whatsapp)</Button>
                            <Button icon={<ShoppingCartOutlined />} size="large">Adicionar ao carrinho</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Item;