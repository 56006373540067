import Main from "./../screens/Main";
import Links from "./../screens/Links";
import Section from "./../screens/Section";
import Item from "./../screens/Item";
// import Error404 from "./../screens/Error404";

// -----------------------------------------------------------------------------
// Routes
// -----------------------------------------------------------------------------
export const ROUTES = [
	{path: "/", component: Main},
	{path: "/links", component: Links},
	{path: "/section", component: Section},
	{path: "/item", component: Item},

	// Error 404
	// {path: "*", component: Error404, showNavbar: true, showBanner: true, showFooter: true},
];