import { Component } from "react";
// import { Link } from "react-router-dom";
// import { AiOutlineHome } from "react-icons/ai";
// import { MdOutlineLeaderboard } from "react-icons/md";
// import { AiOutlineShop } from "react-icons/ai";
// import { RiDiscordLine } from "react-icons/ri";
// import { FiHelpCircle } from "react-icons/fi";
// import { CgLogIn } from "react-icons/cg";
// import { withRouter } from "react-router-dom";
// import axios from "axios";

interface SectionCardsProps {
    text: string;
    image: string;
}

class SectionCard extends Component<SectionCardsProps> {
    // constructor(props: SectionCardsProps) {
    //     super(props);

    // }

    render() {
        const { text, image } = this.props;

        return (
            <div className="section-card" style={{ backgroundImage: `url(${image})` }}>
                <p>
                    {text ?? "none"}
                </p>
            </div>
        );
    }
}

export default SectionCard;